*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    overflow: hidden;
    background: black;
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

.loading-bar, .loading-bar-about, .loading-bar-world
{
    position: absolute;
    top: 50%;
    width: 100%;
    height: 2px;
    background: #ffffff;
    transform: scaleX(0.3);
    transform-origin: top left;
    transition: transform 0.5s;
}

.loading-bar.ended, .loading-bar-about.ended, .loading-bar-world.ended
{
    transform: scaleX(0);
    transform-origin: 100% 0;
    transition: transform 1.5s ease-in-out;
}

.point
{
    position: absolute;
    top: 50%;
    left: 50%;
    /* pointer-events: none; */
}

.point .label
{
    position: absolute;
    top: -20px;
    left: -20px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #ffffff77;
    border: 1px solid #00000077;
    color: #000000;
    font-family: Helvetica, Arial, sans-serif;
    text-align: center;
    line-height: 40px;
    font-weight: 700;
    font-size: 14px;
    cursor: help;
    transform: scale(0, 0);
    transition: transform 0.3s;
}

.point .text
{
    position: absolute;
    top: 30px;
    left: -120px;
    width: 200px;
    padding: 20px;
    border-radius: 4px;
    background: #00000077;
    border: 1px solid #ffffff77;
    color: #ffffff;
    line-height: 1.3em;
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 100;
    font-size: 14px;
    opacity: 0;
    transition: opacity 0.3s;
    pointer-events: none;
}

.point:hover .text
{
    opacity: 1;
}

.point.visible .label
{
    transform: scale(1, 1);
}


.camMenu {
    position: absolute;
    left: 20px;
    top: 20px;
    display: none;
}

.camMenu ul {
    padding: 0px;
    list-style: none;
}

.camMenu ul li {
    display: inline-block;
    /*padding: 10px 30px;*/
    margin-right: 15px;
}

.camMenu ul li a {
    padding: 10px 30px;
    /*background: red;*/
    border: 1px solid black;
    display: inline-block;
    cursor: pointer;
}

/*transition barba*/
.barba-loader {
    position: fixed;
    width: 100vw;
    height: 200vh;
    pointer-events: none;
    background-color: #FFF6ED;
    z-index: 1;
    visibility: hidden;
    opacity: 0;
}

.is-transitioning {
    pointer-events: none;
    cursor: progress;
}
